<template>
  <div class="userInfoIndexPage">
    <h4 class="header">当前位置：个人中心 / 个人资料</h4>
    <a-card :bordered="false"> 
        <h4 class="card-head">个人资料</h4>
        <div class="card-content">
            <div class="item left">
                <h4>班级名称</h4>
                <div class="info one_line">{{classInfo['class_name'] || '暂无'}}</div>
            </div>
            <div class="item left">
                <h4>学期代号</h4>
                <div class="info one_line">{{classInfo['term_code'] || '暂无'}}</div>
            </div>
            <div class="item left">
                <h4>课程代号</h4>
                <div class="info one_line">{{classInfo['course_code'] || '暂无'}}</div>
            </div>
            <div class="item left">
                <h4>课程名称</h4>
                <div class="info one_line">{{classInfo['course_name'] || '暂无'}}</div>
            </div>
            <div class="item left">
                <h4>学生姓名</h4>
                <div class="info one_line">{{userInfo.memberName || '暂无'}}</div>
            </div>
            <div class="item left">
                <h4>联系电话</h4>
                <div class="info one_line">{{userInfo.mobile || '暂无'}}</div>
            </div>
            <div class="item left">
                <h4>联系邮箱</h4>
                <div class="info one_line">{{userInfo.email || '暂无'}}</div>
            </div>
        </div>
    </a-card>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
export default {
  name: "UserInfoIndex",
  created(){
  },
  computed: {
    ...mapGetters(["classIndex","userInfo","classInfo"]),
  },
};
</script>